.rdrDateDisplayWrapper {
  background-color: #ffff;
}

.rdrDateDisplay {
  /* justify-content: start; */
  margin-top: 0 !important;
}

.rdrDateDisplayItem {
  flex: none;
  width: 120px;
  margin: 0 !important;
  /* box-shadow: none; */
  padding: 5px;
  /* min-width: 100px;
  max-width: 120px; */

}

.rdrMonthAndYearPickers {
  color: #32325d;
  font-size: 14px;
}

.rdrMonthAndYearPickers select, .rdrWeekDay {
  font-weight: 600;
  color: #32325d;
  font-size: 12px;
}
.rdrDayNumber span {
  color: #32325d !important;
}

.rdrDayPassive .rdrDayNumber span {
  color: #adb5bd !important;
}
.rdrNextPrevButton button:hover {
  background-color: #adb5bd !important;
}

.rdrPprevButton i {
  border-right-color: #32325d;
}

.rdrNextButton i:hover {
  border-left-color: #32325d;
}

.rdrMonthPicker select, .rdrYearPicker select {
  border: none !important;
  outline: none;
}

.rdrDay, .rdrDayToday, .rdrStartEdge, .rdrEndEdge {
  color: #5e72e4 !important;
}

.rdrInRange {
  color: #d9dcf0 !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  color: #32325d !important;
}

.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
  color: #ffff !important;
}

.rdrDateDisplay {
  color: transparent !important;
}
.rdrDateDisplayItemActive {
  border-color: transparent !important;
  /* background-color: #5e72e4; */
  /* color: #5e72e4 !important; */
}

.rdrDateDisplayItemActive input {
  color: #5e72e4 !important;
}

.rdrDateDisplayItem input {
  font-size: 16px;
  font-weight: 600;
  color: #32325d;
  height: 1.5em;
  line-height: 1.5em;
  text-align: center;
  padding: 0;
}

.rdrDayStartPreview, .rdrDayEndPreview, .rdrDayHovered, .rdrDayInPreview {
  border-color: #32325d;
}

.rdrMonthPicker option, .rdrYearPicker option {
  /* margin: 0; */
  padding: 2px 5px;
  background-color: #ffff;
  color: #32325d;
  border: none !important;
  outline: none;
}

.rdrMonthPicker option:hover, .rdrYearPicker option:hover {
  background-color: #5e72e4 !important;
  color: #ffff !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #5e72e4;
}

.rdrInRange ~ .rdrDayNumber span:after {
  background: #32325d !important;
}

.dateRangeTitle {
  margin: 2em 0.833em;
  font-size: 11px;
}

.dateRangeBottomOptions {
  margin: 24px 15px;
}

.dateRangeBottomOptions button {
  margin-right: 10px;
}

.startEndDate {
  margin: 0 0.833em;
  display: flex;
  flex-direction: row;
  justify-content: space-between
}

.startEndDate div {
  width: 120px;
  font-size: 11px;
  height: 20px;
  line-height: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #32325d;
  /* text-align: start; */
}

.rdrSelected {
  color: #5e72e4  !important;
}
.rdrSelected ~ .rdrDayNumber span {
  color: #ffffff !important;
}