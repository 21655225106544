.trk-innerpage {
    height: 100%;
  }
  .documents-container {
    background-color: #f8f9ff;
    height: 100%;
    /* padding-top: 60px; */
  }
  .panel-container {
    display: flex;
    padding: 45px 0 10px;
    width: 100%;
  }
  .panel-btn {
    background-color: #fff;;
    border-radius: 4px;
    border: 0;
    color: #5e72e4;
    cursor: pointer;
    display: flex;
    height: 32px;
    margin: 0 15px 0 0;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 4px 6px 0 rgba(50, 50, 93, 0.11);
    width: 250px;
  }
  .panel-btn.active {
    background-color: #5e72e4;
    color: #fff;
  }
  .panel-tab {
    display: none;
  }
  .panel-tab.active {
    display: block;
  }
  
  .document-detail-container {
    background-color: #fff;
    border-radius: 4px;
    padding: 15px 0;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(50, 50, 93, 0.21);
  }
  .main-description {
    padding: 0 15px;
  }
  .contents-tab {
    display: none;
  }
  .contents-tab.active {
    display: block;
  }
  .tabs-list {
    border-bottom: 2px solid #f8f9ff;
    list-style: none;
    padding: 0;
  }
  .tabs-list li {
    display: inline-block;
    cursor: pointer;
    color: #32325d;
    font-size: 14px;
    position: relative;
    padding: 10px 15px;
  }
  .tabs-list li.active {
    font-weight: bold;
  }
  .tabs-list li.active:before {
    content: '';
    border-bottom: 3px solid #5e72e4;
    border-radius: 10px;
    position: absolute;
    left: 20%;
    bottom: -2px;
    width: 60%;
  }
  .widget-list {
    padding: 10px 15px 0px 20px;
     margin-bottom: 0;
    list-style: none;
  }

  .widget-detail {
    color: #32325d;
    display: flex;
    padding: 5px 15px;
    flex-wrap: wrap;
    padding-right: 10px;
    width: calc(100% - 120px);
  }
  .widget-detail p:last-child {
    margin-bottom: 0;
  }
  .widget-header {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
  }
  .widget-title {
    font-size: 15px;
    font-weight: bold;
    margin: 0 10px 0 0;
  }
  .widget-updates-status {
    font-size: 12px;
    opacity: 0.7;
  }
  .widget-download {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
  }
  .btn-download {
    background-color: #5e72e4;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    border: 0;
    display: flex;
    font-size: 12px;
    height: 32px;
    align-items: center;
    padding: 5px 10px;
    width: 96px;
  }
  .btn-download .img-btn {
    margin-right: 4px;
  }
  button:focus {
    outline: 0;
  }
  